html, body {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", Verdana, sans-serif;
  background-color: #E5F1EE;
  color: #282828;
}

body {
  overflow-x: hidden;
}

.markup a:after {
  content: ' 🔗 ';
}

.markup a:before {
  content: ' ';
}

.markup a {
  text-decoration: underline;
  color: #282828;
}

.container {
  padding-top: 55px;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  flex: 1;
}

@media (max-width: 1024px) {
  .row {
    flex-direction: column;
  }
}