:root {
  --swiper-theme-color: #007556;
}

* {
  box-sizing: border-box;
}

#app {
  position: static;
  overflow: visible;
}

.markup {
  text-align: left;
}

.markup ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.markup p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.markup h1,
.markup h2,
.markup h3,
.markup h4,
.markup h5,
.markup h6 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.markup h1:first-child,
.markup h2:first-child,
.markup h3:first-child,
.markup h4:first-child,
.markup h5:first-child,
.markup h6:first-child {
  margin-top: 0;
}

.markup strong {
  padding-left: 6px;
  padding-right: 6px;
}

.popup {
  position: fixed;
  z-index: 999;
  padding-top: 55px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}
.popup img {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 750px;
  height: auto;
  animation: zoom 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@media (max-width: 1024px) {
  .popup img {
    width: 100%;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .popup img {
    width: auto;
    height: 70%;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Verdana, sans-serif;
  background-color: #e5f1ee;
  color: #282828;
}

body {
  overflow-x: hidden;
}

.markup a:after {
  content: ' 🔗 ';
}

.markup a:before {
  content: ' ';
}

.markup a {
  text-decoration: underline;
  color: #282828;
}

.container {
  padding-top: 55px;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  flex: 1;
}

@media (max-width: 1024px) {
  .row {
    flex-direction: column;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.mySwiper2 .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-fit: contain;
  max-height: 830px;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.mySwiper2 .swiper-slide {
  background-size: cover;
  background-position: center;
  position: relative;
  align-self: center;
}

.mySwiper2 {
  height: 80%;
  width: 80%;
}

.mySwiper {
  width: 80%;
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.mySwiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-fit: contain;
  max-height: 182px;
}

.slide-text {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
  text-align: left;
  padding-left: 42px;
  font-weight: bold;
}